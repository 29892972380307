import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withCartSummary } from "./withCartSummary"
import CartTotals from "./CartTotals"
import CartDiscountCode from "./CartDiscountCode"

const Wrapper = tw.div`
  w-full md:w-1/2 px-2-4 md:pl-12
`

const Title = tw.p`
  text-20 leading-1.4 mb-2-4 md:mb-3-2 font-medium
`

const DiscountCode = styled(CartDiscountCode)`
  ${tw`mb-4`}
`

const Totals = styled(CartTotals)`
  ${tw`mb-3-2`}
`

const Checkout = tw.a`
  h-5-6 text-16 bg-orange w-full block flex items-center justify-center text-lightest
`

const CartSummary = withCartSummary(({ layout, webUrl }) => {
  return (
    <Wrapper>
      <Title>Your totals</Title>
      <DiscountCode layout={layout} />
      <Totals layout={layout} />
      <Checkout height={`56`} textSize={`16`} colour={`orange`} href={webUrl}>
        Checkout
      </Checkout>
    </Wrapper>
  )
})

CartSummary.propTypes = {
  layout: PropTypes.string,
  webUrl: PropTypes.string,
}

export default CartSummary
