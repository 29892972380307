import React from "react"
import { graphql } from "gatsby"

import { Cart as Page } from "../components/Cart/Cart"

export const query = graphql`
  query {
    page: sanityPageCart {
      title
      emptyTitle
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
