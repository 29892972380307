import React, { useState, useEffect } from "react"

import { useCheckout } from "../../hooks/useCheckout"
import { useApp } from "../../hooks/useApp"

export const withCartDeliveryOptions = Component => ({ name = "CartDeliveryOptions" }) => {
  const { updateAttributes, checkout } = useCheckout()
  const { selectedStore, setSelectedStore } = useApp()
  const [delivery, setDelivery] = useState("")
  const [popupActive, setPopupActive] = useState(false)
  const [collectStore, setCollectStore] = useState(selectedStore)

  useEffect(() => {
    if (checkout && checkout.customAttributes) {
      const cncSet = checkout.customAttributes.find(att => att.key === "clickAndCollect")
      if (cncSet && cncSet.value === "true") {
        setDelivery("collect")
      }
      if (!cncSet || cncSet.value === "false") {
        setDelivery("delivery")
      }
    }
  }, [checkout])

  useEffect(() => {
    if (checkout && checkout.customAttributes && delivery === "delivery") {
      const cncSet = checkout.customAttributes.find(att => att.key === "clickAndCollect")
      if (cncSet && cncSet.value === "true") {
        updateAttributes({
          customAttributes: [{ key: "clickAndCollect", value: "false" }],
        })
        setSelectedStore("")
      }
    }
  }, [delivery, checkout, updateAttributes, setSelectedStore])

  useEffect(() => {
    if (checkout && checkout.customAttributes && delivery === "collect") {
      const cncSet = checkout.customAttributes.find(att => att.key === "clickAndCollect")
      if (cncSet && cncSet.value === "false") {
        if (collectStore) {
          updateAttributes({
            customAttributes: [
              { key: "clickAndCollect", value: "true" },
              { key: "collectStore", value: collectStore },
            ],
          })
          setSelectedStore(collectStore)
        } else {
          updateAttributes({
            customAttributes: [{ key: "clickAndCollect", value: "true" }],
          })
          setPopupActive(true)
        }
      }
    }
  }, [delivery, checkout, updateAttributes, collectStore, setSelectedStore])

  Component.displayName = name
  return (
    <Component
      delivery={delivery}
      setDelivery={setDelivery}
      popupActive={popupActive}
      setPopupActive={setPopupActive}
      collectStore={collectStore}
      setCollectStore={setCollectStore}
    />
  )
}
