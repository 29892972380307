import React from "react"

import { useCheckout } from "../../hooks/useCheckout"

export const withCartLineitems = Component => ({ name = "CartLineitems" }) => {
  const { checkout } = useCheckout()

  Component.displayName = name
  return <Component lineitems={checkout?.lineItems} />
}
