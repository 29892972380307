import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { navigate } from "gatsby"

import { withCart } from "./withCart"
import PageTitle from "../PageTitle/PageTitle"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import { StyledButton } from "../Styled/Button"
import CartDeliveryOptions from "./CartDeliveryOptions"
import CartLineitems from "./CartLineitems"
import CartSummary from "./CartSummary"
import { Sections } from "../Sections/Sections"

const Container = styled(StyledContainer)`
  ${tw`pt-1-6 md:pt-0 pb-8 md:pb-12 flex flex-wrap max-w-xl mx-auto md:px-7-2`}
`

const Text = tw.div`
  text-19 leading-2.22
`

const Button = styled(StyledButton)`
  ${tw`w-full max-w-41 my-4 md:mt-3-2 md:mb-5-6`}
`

export const Cart = withCart(({ title, emptyTitle, paths, quantity, page }) => {
  return (
    <>
      <PageTitle title={quantity === 0 ? emptyTitle : title} paths={paths}>
        {quantity > 0 && (
          <Text>
            {quantity}&nbsp;{quantity === 1 ? `item` : `items`}
          </Text>
        )}
        {quantity === 0 && (
          <Button height={`56`} textSize={`16`} colour={`green`} onClick={() => navigate("/")}>
            Start shopping
          </Button>
        )}
      </PageTitle>
      <BackgroundContainer background={`light`}>
        <Container width={`full`}>
          <CartDeliveryOptions />
          {quantity > 0 && <CartLineitems />}
          <CartSummary />
        </Container>
      </BackgroundContainer>
      <Sections page={page} />
    </>
  )
})

Cart.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  quantity: PropTypes.number,
}

export default Cart
