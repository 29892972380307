import React from "react"

import { useCheckout } from "../../hooks/useCheckout"

export const withCart = Component => ({ name = "Cart", page }) => {
  const { cartItemsQuantity } = useCheckout()

  const paths = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: page?.title,
    },
  ]

  Component.displayName = name
  return <Component title={page?.title} emptyTitle={page?.emptyTitle} paths={paths} quantity={cartItemsQuantity} page={page} />
}
