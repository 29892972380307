import React from "react"

import { useCheckout } from "../../hooks/useCheckout"

export const withCartSummary = Component => ({ name = "CartSummary", layout }) => {
  const {
    checkout: { webUrl },
  } = useCheckout()

  Component.displayName = name
  return <Component layout={layout} webUrl={webUrl} />
}
