import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withCartLineitems } from "./withCartLineitems"
import { LineitemProps } from "../../props/LineitemProps"
import CartLineitemCard from "./CartLineitemCard"

const Wrapper = tw.div`
  w-full md:w-1/2 px-2-4 md:px-0 mb-3-2 md:mb-0
`

const Title = tw.p`
  text-20 leading-1.4 mb-2-4 md:mb-3-2 font-medium
`

const Card = styled(CartLineitemCard)`
  ${({ last }) => (last ? tw`mb-0` : tw`mb-3-2`)}
`

const CartLineitems = withCartLineitems(({ lineitems }) => {
  return (
    <Wrapper>
      <Title>Your items</Title>
      {lineitems?.map((lineitem, index) => (
        <Card key={index} lineitem={lineitem} last={index === lineitems.length - 1} layout={`cart`} />
      ))}
    </Wrapper>
  )
})

CartLineitems.propTypes = {
  lineitems: PropTypes.arrayOf(PropTypes.shape(LineitemProps)),
}

export default CartLineitems
