import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { withCartDeliveryOptions } from "./withCartDeliveryOptions"
import { StyledButton } from "../Styled/Button"

const Wrapper = tw.div`
  w-full mb-3-2 md:mb-4-8
`

const Title = tw.p`
  text-20 leading-1.4 mb-2-4 md:mb-3-2 font-medium px-2-4 md:px-0
`

const Options = tw.div`
  p-1-6 bg-lightest flex flex-wrap
`

const Option = tw.div`
  w-full md:w-1/2 p-0-8 md:p-1-6
`

const Button = styled(StyledButton)`
  ${tw`w-full`}
  ${({ active }) => (active ? tw`bg-green text-lightest` : `bg-transparent`)}
`

const Collect = tw.div`
  mt-1-6 inline text-12 leading-1.56 flex flex-row justify-center
`

const Store = tw.p`font-medium`

const ChangeStore = tw.button`
  focus:outline-none underline
`

const CartDeliveryOptions = withCartDeliveryOptions(({ collectStore, delivery, setDelivery }) => {
  // const [active, setActive] = useState(`delivery`)
  return (
    <Wrapper>
      <Title>Your delivery</Title>
      <Options>
        <Option>
          <Button height={`64-56`} textSize={`16`} border active={delivery === `delivery`} onClick={() => setDelivery(`delivery`)}>
            Delivery
          </Button>
        </Option>
        <Option>
          <Button height={`64-56`} textSize={`16`} border active={delivery === `collect`} onClick={() => setDelivery(`collect`)}>
            Click and Collect
          </Button>
          <Collect>
            {collectStore ? (
              <>
                Collection from&nbsp;
                <Store>{collectStore}</Store>
                &nbsp;&#124;&nbsp;
                <ChangeStore>Change Store</ChangeStore>
              </>
            ) : (
              <ChangeStore>Choose Store</ChangeStore>
            )}
          </Collect>
        </Option>
      </Options>
    </Wrapper>
  )
})

CartDeliveryOptions.propTypes = {
  className: PropTypes.string,
  collectStore: PropTypes.string,
}

export default CartDeliveryOptions
